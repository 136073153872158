<template>
  <div class="page-container find-pwd-email">
    <div class="page-header">
      <ace-header show-back :back-func="onBack">
        {{ $t('system.login.findPassword') }}
      </ace-header>
    </div>
    <div class="page-body">
      <template v-if="!showResult">
        <van-form
          validate-first
          :show-error-message="false"
          validate-trigger="onSubmit"
          @submit="onSubmit"
          @failed="onFailed"
        >
          <div class="mx-4px">
            <van-field
              v-model="formData.email"
              clearable
              maxlength="50"
              autocomplete="off"
              name="email"
              :placeholder="$t('system.login.email')"
              :rules="[{ pattern: emailReg, message: $t('system.login.emailValidateMsg') }]"
              class="mt-12px"
            />
            <div class="mt-30px mx-14px">
              <van-button
                block
                type="primary"
                :disabled="disabled"
                :loading="loading"
                native-type="submit"
              >
                {{ $t('common.okText') }}
              </van-button>
            </div>
          </div>
        </van-form>
      </template>
      <div v-if="showResult" class="pt-220px px-50px h-full text-center">
        <van-icon name="envelop-o" size="80" color="#0080fd" />
        <div class="mt-10px leading-6">{{ $t('system.login.findEmailSent') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
  import { findPassword } from '@/api/user';

  export default {
    name: 'Login',
    data() {
      return {
        emailReg: /^[A-Za-z0-9]+([_.][A-Za-z0-9]+)*@([A-Za-z0-9-]+\.)+[A-Za-z]{2,6}$/,
        disabled: true,
        loading: false,
        formData: {
          email: '',
        },
        showResult: false,
      };
    },
    methods: {
      onBack() {
        this.$router.replace({ name: 'home' });
      },
      async onSubmit(values) {
        try {
          this.loading = true;
          const res = await findPassword(values.email);
          if (res) {
            this.showResult = true;
          }
        } finally {
          this.loading = false;
        }
      },
      onFailed({ errors }) {
        this.$toast(errors[0].message);
      },
    },
    watch: {
      formData: {
        handler(data) {
          if (data.email) {
            this.disabled = false;
          } else {
            this.disabled = true;
          }
        },
        deep: true,
        immediate: true,
      },
    },
  };
</script>
